import { render, staticRenderFns } from "./WechatQRcode.vue?vue&type=template&id=48bf1848&scoped=true&"
import script from "./WechatQRcode.vue?vue&type=script&lang=js&"
export * from "./WechatQRcode.vue?vue&type=script&lang=js&"
import style0 from "./WechatQRcode.vue?vue&type=style&index=0&id=48bf1848&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48bf1848",
  null
  
)

export default component.exports