<!--  -->
<template>
  <div class="header">
    <el-row>
      <el-col :span="5" class="header-left">
        <router-link class="align" :to="'/home'">
          <img
            class="header-left-brandlogo"
            src="https://12cang.oss-cn-hangzhou.aliyuncs.com/assets/login/brand-logo.png"
            alt=""
          />
          <img
            class="header-left-brandname"
            src="https://12cang.oss-cn-hangzhou.aliyuncs.com/assets/login/brand-name.png"
            alt=""
          />
          <span class="header-left-text">商家后台</span>
        </router-link>
      </el-col>
      <el-col :span="19" class="header-right flex-right align">
        <ul class="header-ul flex-right">
          <!-- <li class="header-msg" style="padding-right: 30px;">
            <el-badge :value="notifyCounts" :max="99" :hidden="notifyCounts === 0" class="item">
              <span>
                <a href="/msg-center/download" target="_blank">消息中心</a>
              </span>
            </el-badge>
          </li> -->
          <li class="header-username" style="padding-right: 14px;">
            <span>{{ propName ? propName : userName }}</span>
          </li>
        </ul>
        <el-tooltip content="退出登录" placement="bottom" effect="light">
          <el-button class="header-loginOut-btn" @click="loginOut">
            <i class="el-icon-switch-button"></i>
          </el-button>
        </el-tooltip>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    propName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      brandLogo: require('@/assets/home/brand-logo.png'),
      brandName: require('@/assets/home/brand-name.png'),
      headMsg: `${'今天是周' +
        '日一二三四五六'.charAt(new Date().getDay())}，预计又是个订单满满的一天`,
      userName: this.$store.state.userInfo.mobile.replace(
        new RegExp('(\\d{3})(\\d{4})(\\d{4})'),
        '$1****$3'
      ),
    };
  },

  components: {},

  computed: {
    notifyCounts() {
      // 渠道选择
      return this.$store.state.notifyCounts;
    },
  },

  mounted() {
    // this.getNotifyCounts();
    // setInterval(() => {
    //   this.getNotifyCounts();
    // }, 10000);
  },

  methods: {
    getNotifyCounts() {
      this.$store.dispatch('getNotifyCounts');
    },
    loginOut() {
      this.$confirm('是否退出登陆?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$store.commit('SET_TOKEN', '');
        this.$store.commit('SET_USERINFO', '');
        this.$store.commit('SET_MERCHANTTYPE', null);
        this.$router.replace({ path: '/login' });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.header {
  color: #fff;
  padding: 16px 50px;
  line-height: 26px;
  background: #30343b;
  .header-left {
    .header-left-brandlogo {
      width: 26px;
      height: 26px;
      margin-right: 5px;
    }
    .header-left-brandname {
      width: 58px;
      height: 17px;
    }
  }
  .header-right {
    .header-ul {
      li {
        height: 26px;
        line-height: 26px;
        padding: 0 18px;
      }
    }
    .header-msg /deep/ .el-badge__content {
      transform: scale(0.9) translateX(100%);
      top: -4px;
      right: 5px;
    }
    // .header-username {
    //   position: relative;
    //   &:before {
    //     content: '';
    //     position: absolute;
    //     top: 6px;
    //     left: 0;
    //     width: 1px;
    //     height: 14px;
    //     background-color: #626364;
    //   }
    // }
    .header-loginOut-btn {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      padding: 0;
      text-align: center;
      background: #c1c2c4;
      border: none;
      opacity: 0.7;
    }
    .el-icon-switch-button {
      color: #30343b;
      font-weight: 600;
    }
  }
}
</style>
