<template>
  <el-dialog
    width="500px"
    class="wechat-dialog"
    title="提交成功"
    center
    :visible.sync="visible"
    :destroy-on-close="true"
    :lock-scroll="false"
    append-to-body
    :before-close="handleClose"
  >
    <div class="img-con">
      <p>运营会在48小时内审核，可扫描下方二维码联系运营</p>
      <img
        src="https://12cang.oss-cn-hangzhou.aliyuncs.com/assets/images/mochan-qrcode.jpg"
        alt=""
      />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleClose">知道了</el-button>
    </span>
  </el-dialog>
</template>

<script>
import DialogMixin from '@/mixin/dialog';
export default {
  name: 'GoodsEdit',
  mixins: [DialogMixin],
  data() {
    return {};
  },
  watch: {
    'extra.formData': {
      handler(newVal, oldVal) {
        this.formData = JSON.parse(JSON.stringify(newVal));
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    closeCallBack() {},
  },
};
</script>

<style lang="less" scoped>
.wechat-dialog {
  .img-con {
    text-align: center;
    img {
      width: 50%;
    }
  }
}
</style>
